/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
//require("./src/styles/global.css")
import "./src/styles/global.css"

//import "@fontsource/raleway"
//import "@fontsource/roboto"
import "@fontsource/overpass/200.css"
import "@fontsource/overpass/300.css"
import "@fontsource/inter/200.css"
//import "@fontsource/nunito-sans"
//import "@fontsource/open-sans"
export const onClientEntry = async () => {
    if (typeof IntersectionObserver === "undefined") {
      await import("intersection-observer")
    }
}